<template>
  <div class="sub-table-link" :style="tableStyle">
    <div v-if="level === 2" class="header">{{ title }}</div>
    <en-table
      ref="table"
      :loading="loading"
      :heightConfig="heightConfig"
      :head-end-config="{type:'more',moreChangeMethod: handleMoreChange}"
      :data="showDatas"
       :hide-columns="hideList"
      :no-data-icon-size="noDataIconSize"
      :page-config="pageConfig"
      @select-change="handleSelectChange"
      :span-method="spanMethod"
      @filter-sort-change="handleColumnFilterChange"
    >
      <en-table-column v-if="!loading" width="50" type="index-selection" title="序号" fixed='left'>
        <!-- <template slot-scope="scope">
          <el-checkbox
            v-model="scope.row.selected"
            @change="selectedChange(scope.row)"
            :indeterminate="scope.row.selectedAll"
          ></el-checkbox>
        </template> -->
      </en-table-column>
      <table-column v-for="(col, index) in showList" :key="col.id || index" :data="col"></table-column>
      <en-table-column
        v-if="subShowInfo"
        :title="subShowInfo.name"
        key="subset"
        min-width="100"
      >
        <template slot-scope="scope">
          <div @click="clickHandleSub(scope)" class="click-class">
            <div class="bg"></div>
            <en-icon name="ziji-kongjian"></en-icon>
            <div class="text">
              {{ scope.row.openSubset ? "点击收起" : "点击查看" }}
            </div>
          </div>
        </template>
      </en-table-column>
      <template v-if="level < levelCount" #hoverRow="{row, rowIndex}">
        <en-expand-buttons
          v-if="level === 1"
          :data="btnDatas"
          @button-click="
            handleButtonClick({
              row,
              rowIndex,
              btnData: arguments[1],
              btnIndex: arguments[0]
            })
          "
        >
        </en-expand-buttons>
      </template>
      <en-table-column v-if="level < levelCount" type="expand" prop="id" width="0">
        <template slot-scope="scope">
            <sub-table2
              @change="updateSelectedCount"
              :class="level === 1 ? 'sub-table-level1':'sub-table-level2'"
              :key="level + 1"
              :parentData="scope.row"
              no-data-icon-size="small"
              :column="column"
              :business-data="businessData"
              :level="level + 1"
              :sub-table-width="tableWidth"></sub-table2>
        </template>
      </en-table-column>
      <template #paginationLeft>
         <div class="field-statistics" v-if="!selectedCount">
          <div v-for="(value, name) of fieldStatistics" :key="name" class="statistics-item">
            <strong>
              {{name == 2 ? "全部" : "本页"}} :
            </strong>
            <span class="content" v-for="item in value" :key="item.id">
              {{item.statisticsShow}}: {{item.value}}
            </span>
          </div>
        </div>
        <div v-else-if="level === 1" class="footer-left">
          <div class="label">已选<span class="count">{{ selectedCount }}</span>条数据</div>
          <div class="clear"  @click="clear">清空</div>
        </div>
      </template>
      <template #pagination>
        <div v-if="isCoverAndAppend&&!loading && level === 1">
          <en-button style="margin-left: 10px;" type="green"  @click="submit('append')">
            <el-tooltip effect="dark" content="此次选择的数据累加在原有数据后方" placement="bottom-start">
              <span>依次新增</span>
            </el-tooltip>
          </en-button>
          <en-button  @click="submit('cover')">
            <el-tooltip effect="dark" content="原有数据被删除，此次选择的数据将替代原有数据" placement="bottom-start">
              <span>覆盖新增</span>
            </el-tooltip>
          </en-button>
        </div>
        <en-button v-else-if='!loading && level === 1'  @click="submit('cover')" style="margin-left:15px">
              <span>确 定</span>
          </en-button>
      </template>
    </en-table>
    <!-- 选中后提示 type -->
    <!-- <en-dialog  :visible="showTip" width="450px" @close="showTip = false" append-to-body>
      <div class="dialog-linkadd--content">
        <div class="msg">
          <en-icon name='tishi' class='tishi-icon' size='26'></en-icon>
          已选择{{ checkResult.length }}项数据</div>
      </div>
      <span slot="footer" class="group-btn">
        <en-button type="white" @click="submit('append')">依次新增数据</en-button>
        <en-button class="assist-btn" @click="submit('cover')">覆盖已有数据</en-button>
      </span>
    </en-dialog> -->
  </div>
</template>

<script>
import { groupBy } from "lodash";
import TableColumn from "@/components/en-table-extra/tableColumn";
import { BusinessFieldType } from "@/components/businessDetail/data/BusinessCommonHeader";
import { Message } from "element-ui";
import { businessService } from "@/api/businessModel";
import { relationDataHandle } from "./relationDataHandle";

export default {
  name: "subTable2",
  props: {
    noDataIconSize: String,
    parentData: {
      type: Object
    },
    column: {
      type: Object,
      require: true
    },
    level: {
      type: Number,
      default: 1
    },
    businessData: {
      type: Object
    },
    subTableWidth: {
      type: Number
    }
  },
  components: {
    TableColumn
  },
  data() {
    return {
      tableWidth: 0,
      loading: false,
      // showTip: false,
      btnDatas: [
        {
          icon: "xiangqing-liebiao",
          iconColor: "#4ABEF2"
        }
      ],
      showDatas: [],
      showList: [],
      title: "",
      checkResult: [],
      subShowInfo: null,
      pageModel: {
        pageNo: 1,
        pageSize: 10,
        total: null,
        totalPages: null,
        data: null
      },
      fieldStatistics: {},
      selectedCount: 0,
      hideList: [],
      historyList: [],
      info: {}
    };
  },
  async mounted() {
    this.init();
  },
  computed: {
    heightConfig() {
      if (this.level > 1) {
        return {};
      }
      return {
        bottomHeight: "10px"
      };
    },
    tableStyle() {
      if (this.subTableWidth) {
        return `width:${this.subTableWidth - 20}px`;
      }
      return "";
    },
    checkRowIds() {
      return this.column.subDetails?.filter((item) => !!item.refData?.id).map((rd) => rd.refData?.id);
    },
    isCoverAndAppend() {
      // console.log(this.column.onlyDefaultEmptyLine());
      return this.column.onlyDefaultEmptyLine
        ? this.selectedCount && this.column.subDetails.length && !this.column.onlyDefaultEmptyLine()
        : this.selectedCount && this.column.subDetails.length;
    },
    // 分页
    pageConfig() {
      return this.level === 1 ? { pageModel: this.pageModel, changeMethod: this.handlePageChanged } : null;
    },
    // 层级
    levelCount() {
      if (Number(this.column.showListStyle) === 1) {
        return 1;
      }
      return Number(this.column.selAreaType);
    },
    // 列头显示字段
    showContent() {
      if (this.parentData) {
        return this.parentData?.showInfo?.colList;
      }
      const {
        showContent, selAreaField, selAreaParentField
      } = this.column;
      let field = "mainTopArea";
      if (this.level === 2) {
        field = selAreaParentField || selAreaField;
      } else if (this.level === 3) {
        field = selAreaField;
      }
      const List = showContent; // ?.filter((item) => item.listIsShow !== "1" && (item.areaShowField === field || !item.areaShowField));
      console.log("List", List);
      if (List.length === 0) {
        Message({
          showClose: true,
          message: "没有配署显示字段，请联系管理员",
          type: "error"
        });
      }
      return List;
    }
  },
  methods: {
    handleSelectChange(value) {
      console.log(value);
      // 单独选中某一条
      if (value.row) {
        value.row.selected = !value.row.selected;
         relationDataHandle.change(value.row);
        this.updateSelectedCount();
        return;
      }
      // 全选
      if (value.selection.length) {
        value.selection.forEach((it, index) => {
          this.$set(this.showDatas[index], "selected", !!it);
          relationDataHandle.change(it);
          this.updateSelectedCount();
        });
      } else {
        // 取消全选
        this.clear();
      }
    },
    async init() {
      const res = await businessService.getPopupWindowById({
      templateId: this.$route.query.templateId,
      field: this.column.field
    });
    console.log(res, "ressss");
    if (res) {
      this.info = res;
      this.historyList = res.showData;

      console.log(this.hideList);
    }
    const that = this;
    window.onresize = (() => {
      this.$nextTick(() => {
        that.tableWidth = that.$el.clientWidth;
      });
    })();
    this.loading = false;
    if (this.parentData?.children) {
      this.showDatas = this.parentData?.children;
      this.updateShowInfo();
      return;
    }
    this.queryData(true);
    },
    async handleMoreChange(params) {
      const res = await businessService.updatePopupWindow({
        _id: this.info?._id || null,
        hideData: JSON.parse(params.fieldSort).filter((it) => !it.visible),
        showData: JSON.parse(params.fieldSort),
        templateId: this.$route.query.templateId,
        field: this.column.field,
        fixColNum: params.fixedColumnNum
      });
    },
    // 显示内容
    showName(data, item) {
      return relationDataHandle.showName(data, item);
    },
    clear() {
      relationDataHandle.clear(this.pageModel.data);
      this.selectedCount = 0;
    },
    updateSelectedCount() {
      if (this.level > 1) {
        this.$emit("change");
      } else {
        this.selectedCount = relationDataHandle.selectedDataCount(this.pageModel.data, this.column);
        console.log(this.selectedCount);
      }
    },
    selectedChange(row) {
      console.log(row, "-=-=-=-=-=");

      relationDataHandle.change(row);
      this.updateSelectedCount();
    },
    // 合并展开行
    spanMethod({ columnIndex }) {
      if (this.level === 2) {
        if (!this.subShowInfo) {
          return [1, 1];
        }
        if (columnIndex === this.showList.length + 1) {
          return {
            rowspan: 1,
            colspan: 2
          };
        }
        if (columnIndex === this.showList.length + 2) {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
      if (this.level === 1) {
        if (columnIndex === this.showList.length) {
          return {
            rowspan: 1,
            colspan: 2
          };
        }
        if (columnIndex === this.showList.length + 1) {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
      return [1, 1];
    },
    /**
     * 展开收起
     * @param e
     */
    clickHandleSub(e) {
      const data = e.row;
      this.handleButtonClick(e);
      data.openSubset = false;
    },
    /**
     * 查询数据
     */
    queryData(init) {
      this.loading = true;
      relationDataHandle.queryData(this.column, this.businessData, this.pageModel, this.parentData, this.level).then((res) => {
        this.loading = false;
        this.updateShowInfo();
        this.showDatas = res;
        this.fieldStatistics = groupBy(this.pageModel.fieldStatistics, "statisticsScope");
        this.pageModel = { ...this.pageModel };
        if (init) { // 初始化
          relationDataHandle.select(this.pageModel.data, this.checkRowIds);
          this.selectedCount = this.checkRowIds.length;
        } else {
          this.clear();
        }
        this.hideList = this.info.hideData;
      }).catch(() => {
        this.loading = false;
      });
    },
    /**
     * 更新列头
     */
    updateShowInfo() {
      this.showList = this.level === 1 ? this.showContent : this.parentData.showInfo.colList;
      this.showList = this.showList.filter((item) => item.fieldType !== "11");// 隐藏明细字段
      if (this.historyList.length) {
        console.log(this.historyList, "this.historyList");
        this.showList.forEach((it) => {
          this.historyList.forEach((item) => {
            if (item.field === it.field && item.fixed) {
              it.fixed = item.fixed;
            }
          });
        });
      }
      this.title = this.parentData?.showInfo?.name;
      if (this.parentData) {
        this.subShowInfo = this.parentData.subShowInfo;
      }
    },
    /**
     * 加载更多
     */
    addMore() {
      this.queryData();
    },
    /**
     * 展开收起
     * @param e
     */
    handleButtonClick(e) {
      this.$refs.table.toggleRowExpansion(e.row);
    },
    /**
     * 筛选
     * @param e
     */
    handleColumnFilterChange({ filterData, sortData }) {
      console.log("handle column filter change", { filterData, sortData });
      this.pageModel.pageNo = 1;
      if (filterData) {
        filterData = JSON.parse(filterData);
        filterData.forEach((field) => {
        // 多选是模糊搜索
          if (field.fieldType === BusinessFieldType.multiSelect) {
            field.value = field.valueText;
          }
        });
      }
      this.pageModel.filterFields = filterData ? JSON.stringify(filterData) : undefined;
      this.pageModel.sortField = sortData;
      this.queryData();
    },
    // page事件
    handlePageChanged(data) {
      console.log(data);
      Object.assign(this.pageModel, data);
      this.queryData();
    },
    // // 弹窗确定
    // confirmCheck() {
    //   relationDataHandle.getSelectedDatas(this.pageModel.data, this.column).then((response) => {
    //     this.showTip = true;
    //     this.checkResult = response;
    //   });
    // },
    submit(type) {
      relationDataHandle.getSelectedDatas(this.pageModel.data, this.column).then((response) => {
        // this.showTip = true;
        this.checkResult = response;
        console.log("已选", this.checkResult);
        this.$emit("complete", type, this.checkResult);
      });
    },
    filterRender() {
      return {};
    }
  }
};
</script>

<style lang="scss" scoped>
.sub-table-link {
  background-color: white;
  border-bottom: 1px solid #e8ecf2;
  .header {
    color: #333333;
    font-size: 12px;
    line-height: 32px;
    font-weight: bold;
  }
  .assist-btn {
    position: absolute;
    right: 10px;
    bottom: 11px;
  }

  .footer-left {
    display: flex;
    font-size: 12px;
    .count {
      color: #3e90fe;
    }
    .label {
      color: #636C78;
    }
    .clear {
      color: #3e90fe;
      margin-left: 20px;
    }
  }

  .click-class {
    display: flex;
    line-height: 32px;
    position: relative;
    width: 93px;
    height: 32px;
    border-radius: 4px;
    align-items: center;
    .bg {
      position: absolute;
      width: 93px;
      height: 32px;
      opacity: 0.13;
      background: #26C393;
    }
    .en-icon {
      width: 14px;
      height: 14px;
      margin-left: 12px;
      color: #26C393;
    }
    .text {
      display: flex;
      align-items: center;
      width: 48px;
      height: 12px;
      color: #26C393;
      font-weight: 400;
      font-size: 12px;
      margin-left: 6px;
    }
  }

  & .sub-table-level1 {
    padding: 0 10px 10px;
    background-color: white;
  }

  & .sub-table-level2 {
    padding: 10px;
    background-color: #DCE6EF;
  }
}
.dialog-linkadd--content{
    height: 70px;
    line-height: 70px;
    .tishi-icon{
      vertical-align: middle;
      color:#3e90fe ;
      margin-right: 10px;
    }
  }
</style>
